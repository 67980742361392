li{
    color: black;
    text-decoration: none;
    list-style: none;
}
a{
    color: black;
    font-size: 1em;
}
ul{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5em;
}
.nav-list{
    display: block;
}
.nav-list li{
    color: #5b3d98;
}