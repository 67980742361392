

.form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:2px;


}



.form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #c6bcce;
    background-color: rgba(186, 166, 220, 0.65);
    margin: auto;
    text-align: center;
    border-radius: 10px;
    padding-top: 30px;

    text-align: center;

}


label {
    display: block;
    color: #e5e0ee;
}

input {
    padding: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    margin: 1px;
    border-radius: 10px;
}

th {
    text-align: center;
}
