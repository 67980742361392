/**
 * Automatic version:
 * Uses light theme by default but switches to dark theme
 * if a system-wide theme preference is set on the user's device.
 */
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&family=Roboto:ital@0;1&display=swap');

h1{
    font-family: "Roboto", sans-serif;
    font-weight: 200;

}
h2{
    font-family: "Roboto", sans-serif;
    font-weight: 150;

}
p,label, span, li, ul, th, tr{
    font-family: "Roboto", sans-serif;
    font-weight: 150;

}
:root {
    --background-body: #fff;
    --background: #efefef;
    --background-alt: #f7f7f7;
    --selection: #9e9e9e;
    --text-main: #363636;
    --text-bright: #000;
    --text-muted: #70777f;
    --links: #0076d1;
    --focus: #0096bfab;
    --border: #dbdbdb;
    --code: #000;
    --animation-duration: 0.1s;
    --button-base: #d0cfcf;
    --scrollbar-thumb: rgb(170, 170, 170);
    --form-placeholder: #949494;
    --form-text: #1d1d1d;
    --variable: #39a33c;
    --highlight: #ff0;
    --select-arrow: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23161f27'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E");
}

@media (prefers-color-scheme: dark) {
    :root {
        --background-body: #202b38;
        --background: #161f27;
        --background-alt: #1a242f;
        --selection: #1c76c5;
        --text-main: #dbdbdb;
        --text-bright: #fff;
        --text-muted: #a9b1ba;
        --links: #41adff;
        --focus: #0096bfab;
        --border: #526980;
        --code: #ffbe85;
        --animation-duration: 0.1s;
        --button-base: #0c151c;
        --scrollbar-thumb-hover: rgb(0, 0, 0);
        --form-placeholder: #a9a9a9;
        --form-text: #fff;
        --variable: #d941e2;
        --highlight: #efdb43;
        --select-arrow: url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E");
    }
}

html {
    scrollbar-color: rgb(170, 170, 170) #fff;
    scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    scrollbar-width: thin;
}

@media (prefers-color-scheme: dark) {

    html {
        scrollbar-color: #040a0f #202b38;
        scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    }
}

@media (prefers-color-scheme: dark) {

    html {
        scrollbar-color: #040a0f #202b38;
        scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    }
}

@media (prefers-color-scheme: dark) {

    html {
        scrollbar-color: #040a0f #202b38;
        scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    }
}

@media (prefers-color-scheme: dark) {

    html {
        scrollbar-color: #040a0f #202b38;
        scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    }
}

@media (prefers-color-scheme: dark) {

    html {
        scrollbar-color: #040a0f #202b38;
        scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    }
}

@media (prefers-color-scheme: dark) {

    html {
        scrollbar-color: #040a0f #202b38;
        scrollbar-color: var(--scrollbar-thumb) var(--background-body);
    }
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Segoe UI Emoji', 'Apple Color Emoji', 'Noto Color Emoji', sans-serif;
    line-height: 1.4;
    max-width: 800px;
    margin: 20px auto;
    padding: 0 10px;
    word-wrap: break-word;
    color: #363636;
    color: var(--text-main);
    background: #fff;
    background: var(--background-body);
    text-rendering: optimizeLegibility;
}

@media (prefers-color-scheme: dark) {

    body {
        background: #202b38;
        background: var(--background-body);
    }
}

@media (prefers-color-scheme: dark) {

    body {
        color: #dbdbdb;
        color: var(--text-main);
    }
}

button {
    transition: background-color 0.1s linear,
    border-color 0.1s linear,
    color 0.1s linear,
    box-shadow 0.1s linear,
    transform 0.1s ease;
    transition: background-color var(--animation-duration) linear,
    border-color var(--animation-duration) linear,
    color var(--animation-duration) linear,
    box-shadow var(--animation-duration) linear,
    transform var(--animation-duration) ease;
}

@media (prefers-color-scheme: dark) {

    button {
        transition: background-color 0.1s linear,
        border-color 0.1s linear,
        color 0.1s linear,
        box-shadow 0.1s linear,
        transform 0.1s ease;
        transition: background-color var(--animation-duration) linear,
        border-color var(--animation-duration) linear,
        color var(--animation-duration) linear,
        box-shadow var(--animation-duration) linear,
        transform var(--animation-duration) ease;
    }
}

input {
    transition: background-color 0.1s linear,
    border-color 0.1s linear,
    color 0.1s linear,
    box-shadow 0.1s linear,
    transform 0.1s ease;
    transition: background-color var(--animation-duration) linear,
    border-color var(--animation-duration) linear,
    color var(--animation-duration) linear,
    box-shadow var(--animation-duration) linear,
    transform var(--animation-duration) ease;
}

@media (prefers-color-scheme: dark) {

    input {
        transition: background-color 0.1s linear,
        border-color 0.1s linear,
        color 0.1s linear,
        box-shadow 0.1s linear,
        transform 0.1s ease;
        transition: background-color var(--animation-duration) linear,
        border-color var(--animation-duration) linear,
        color var(--animation-duration) linear,
        box-shadow var(--animation-duration) linear,
        transform var(--animation-duration) ease;
    }
}

textarea {
    transition: background-color 0.1s linear,
    border-color 0.1s linear,
    color 0.1s linear,
    box-shadow 0.1s linear,
    transform 0.1s ease;
    transition: background-color var(--animation-duration) linear,
    border-color var(--animation-duration) linear,
    color var(--animation-duration) linear,
    box-shadow var(--animation-duration) linear,
    transform var(--animation-duration) ease;
}

@media (prefers-color-scheme: dark) {

    textarea {
        transition: background-color 0.1s linear,
        border-color 0.1s linear,
        color 0.1s linear,
        box-shadow 0.1s linear,
        transform 0.1s ease;
        transition: background-color var(--animation-duration) linear,
        border-color var(--animation-duration) linear,
        color var(--animation-duration) linear,
        box-shadow var(--animation-duration) linear,
        transform var(--animation-duration) ease;
    }
}

h1 {
    font-size: 2.2em;
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 12px;
    margin-top: 24px;
}

h1 {
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    h1 {
        color: #fff;
        color: var(--text-bright);
    }
}

h2 {
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    h2 {
        color: #fff;
        color: var(--text-bright);
    }
}

h3 {
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    h3 {
        color: #fff;
        color: var(--text-bright);
    }
}

h4 {
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    h4 {
        color: #fff;
        color: var(--text-bright);
    }
}

h5 {
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    h5 {
        color: #fff;
        color: var(--text-bright);
    }
}

h6 {
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    h6 {
        color: #fff;
        color: var(--text-bright);
    }
}

strong {
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    strong {
        color: #fff;
        color: var(--text-bright);
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
th {
    font-weight: 600;
}

q::before {
    content: none;
}

q::after {
    content: none;
}

blockquote {
    border-left: 4px solid #0096bfab;
    border-left: 4px solid var(--focus);
    margin: 1.5em 0;
    padding: 0.5em 1em;
    font-style: italic;
}

@media (prefers-color-scheme: dark) {

    blockquote {
        border-left: 4px solid #0096bfab;
        border-left: 4px solid var(--focus);
    }
}

q {
    border-left: 4px solid #0096bfab;
    border-left: 4px solid var(--focus);
    margin: 1.5em 0;
    padding: 0.5em 1em;
    font-style: italic;
}

@media (prefers-color-scheme: dark) {

    q {
        border-left: 4px solid #0096bfab;
        border-left: 4px solid var(--focus);
    }
}

blockquote > footer {
    font-style: normal;
    border: 0;
}

blockquote cite {
    font-style: normal;
}

address {
    font-style: normal;
}

a[href^='mailto\:']::before {
    content: '📧 ';
}

a[href^='tel\:']::before {
    content: '📞 ';
}

a[href^='sms\:']::before {
    content: '💬 ';
}

mark {
    background-color: #ff0;
    background-color: var(--highlight);
    border-radius: 2px;
    padding: 0 2px 0 2px;
    color: #000;
}

@media (prefers-color-scheme: dark) {

    mark {
        background-color: #efdb43;
        background-color: var(--highlight);
    }
}

a > code,
a > strong {
    color: inherit;
}

button,
select,
input[type='submit'],
input[type='reset'],
input[type='button'],
input[type='checkbox'],
input[type='range'],
input[type='radio'] {
    cursor: pointer;
}

input,
select {
    display: block;
}

[type='checkbox'],
[type='radio'] {
    display: initial;
}

input {
    color: #1d1d1d;
    color: var(--form-text);
    background-color: #efefef;
    background-color: var(--background);
    font-family: inherit;
    font-size: inherit;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 10px;
    border: none;
    border-radius: 6px;
    outline: none;
}

@media (prefers-color-scheme: dark) {

    input {
        background-color: #161f27;
        background-color: var(--background);
    }
}

@media (prefers-color-scheme: dark) {

    input {
        color: #fff;
        color: var(--form-text);
    }
}

button {
    color: #1d1d1d;
    color: var(--form-text);
    font-family: inherit;
    font-size: inherit;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 10px;
    border: none;
    border-radius: 6px;
    outline: none;
}


textarea {
    color: #1d1d1d;
    color: var(--form-text);
    background-color: #efefef;
    background-color: var(--background);
    font-family: inherit;
    font-size: inherit;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 10px;
    border: none;
    border-radius: 6px;
    outline: none;
}

@media (prefers-color-scheme: dark) {

    textarea {
        background-color: #161f27;
        background-color: var(--background);
    }
}

@media (prefers-color-scheme: dark) {

    textarea {
        color: #fff;
        color: var(--form-text);
    }
}

select {
    color: #1d1d1d;
    color: var(--form-text);
    background-color: #efefef;
    background-color: var(--background);
    font-family: inherit;
    font-size: inherit;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 10px;
    border: none;
    border-radius: 6px;
    outline: none;
}

@media (prefers-color-scheme: dark) {

    select {
        background-color: #161f27;
        background-color: var(--background);
    }
}

@media (prefers-color-scheme: dark) {

    select {
        color: #fff;
        color: var(--form-text);
    }
}


input[type='color'] {
    min-height: 2rem;
    padding: 8px;
    cursor: pointer;
}

input[type='checkbox'],
input[type='radio'] {
    height: 1em;
    width: 1em;
}

input[type='radio'] {
    border-radius: 100%;
}

input {
    vertical-align: top;
}

label {
    vertical-align: middle;
    margin-bottom: 4px;
    display: inline-block;
}

input:not([type='checkbox']):not([type='radio']),
input[type='range'],
select,
button,
textarea {
    -webkit-appearance: none;
}

textarea {
    display: block;
    margin-right: 0;
    box-sizing: border-box;
    resize: vertical;
}

textarea:not([cols]) {
    width: 100%;
}

textarea:not([rows]) {
    min-height: 40px;
    height: 140px;
}

select {
    background: #efefef url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23161f27'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) 50% / 12px no-repeat;
    background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    padding-right: 35px;
}

@media (prefers-color-scheme: dark) {

    select {
        background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) 50% / 12px no-repeat;
        background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    }
}

@media (prefers-color-scheme: dark) {

    select {
        background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) 50% / 12px no-repeat;
        background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    }
}

@media (prefers-color-scheme: dark) {

    select {
        background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) 50% / 12px no-repeat;
        background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    }
}

@media (prefers-color-scheme: dark) {

    select {
        background: #161f27 url("data:image/svg+xml;charset=utf-8,%3C?xml version='1.0' encoding='utf-8'?%3E %3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='62.5' width='116.9' fill='%23efefef'%3E %3Cpath d='M115.3,1.6 C113.7,0 111.1,0 109.5,1.6 L58.5,52.7 L7.4,1.6 C5.8,0 3.2,0 1.6,1.6 C0,3.2 0,5.8 1.6,7.4 L55.5,61.3 C56.3,62.1 57.3,62.5 58.4,62.5 C59.4,62.5 60.5,62.1 61.3,61.3 L115.2,7.4 C116.9,5.8 116.9,3.2 115.3,1.6Z'/%3E %3C/svg%3E") calc(100% - 12px) 50% / 12px no-repeat;
        background: var(--background) var(--select-arrow) calc(100% - 12px) 50% / 12px no-repeat;
    }
}

select::-ms-expand {
    display: none;
}

select[multiple] {
    padding-right: 10px;
    background-image: none;
    overflow-y: auto;
}

input:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {

    input:focus {
        box-shadow: 0 0 0 2px #0096bfab;
        box-shadow: 0 0 0 2px var(--focus);
    }
}

select:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {

    select:focus {
        box-shadow: 0 0 0 2px #0096bfab;
        box-shadow: 0 0 0 2px var(--focus);
    }
}

button:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {

    button:focus {
        box-shadow: 0 0 0 2px #0096bfab;
        box-shadow: 0 0 0 2px var(--focus);
    }
}

textarea:focus {
    box-shadow: 0 0 0 2px #0096bfab;
    box-shadow: 0 0 0 2px var(--focus);
}

@media (prefers-color-scheme: dark) {

    textarea:focus {
        box-shadow: 0 0 0 2px #0096bfab;
        box-shadow: 0 0 0 2px var(--focus);
    }
}

input[type='checkbox']:active,
input[type='radio']:active,
input[type='submit']:active,
input[type='reset']:active,
input[type='button']:active,
input[type='range']:active,
button:active {
    transform: translateY(2px);
}

input:disabled,
select:disabled,
button:disabled,
textarea:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

::-moz-placeholder {
    color: #949494;
    color: var(--form-placeholder);
}

:-ms-input-placeholder {
    color: #949494;
    color: var(--form-placeholder);
}

::-ms-input-placeholder {
    color: #949494;
    color: var(--form-placeholder);
}

::placeholder {
    color: #949494;
    color: var(--form-placeholder);
}

@media (prefers-color-scheme: dark) {

    ::-moz-placeholder {
        color: #a9a9a9;
        color: var(--form-placeholder);
    }

    :-ms-input-placeholder {
        color: #a9a9a9;
        color: var(--form-placeholder);
    }

    ::-ms-input-placeholder {
        color: #a9a9a9;
        color: var(--form-placeholder);
    }

    ::placeholder {
        color: #a9a9a9;
        color: var(--form-placeholder);
    }
}

fieldset {
    border: 1px #0096bfab solid;
    border: 1px var(--focus) solid;
    border-radius: 6px;
    margin: 0;
    margin-bottom: 12px;
    padding: 10px;
}

@media (prefers-color-scheme: dark) {

    fieldset {
        border: 1px #0096bfab solid;
        border: 1px var(--focus) solid;
    }
}

legend {
    font-size: 0.9em;
    font-weight: 600;
}

input[type='range'] {
    margin: 10px 0;
    padding: 10px 0;
    background: transparent;
}

input[type='range']:focus {
    outline: none;
}

input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 9.5px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    background: #efefef;
    background: var(--background);
    border-radius: 3px;
}

@media (prefers-color-scheme: dark) {

    input[type='range']::-webkit-slider-runnable-track {
        background: #161f27;
        background: var(--background);
    }
}

input[type='range']::-webkit-slider-thumb {
    box-shadow: 0 1px 1px #000, 0 0 1px #0d0d0d;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #dbdbdb;
    background: var(--border);
    -webkit-appearance: none;
    margin-top: -7px;
}

@media (prefers-color-scheme: dark) {

    input[type='range']::-webkit-slider-thumb {
        background: #526980;
        background: var(--border);
    }
}

input[type='range']:focus::-webkit-slider-runnable-track {
    background: #efefef;
    background: var(--background);
}

@media (prefers-color-scheme: dark) {

    input[type='range']:focus::-webkit-slider-runnable-track {
        background: #161f27;
        background: var(--background);
    }
}

input[type='range']::-moz-range-track {
    width: 100%;
    height: 9.5px;
    -moz-transition: 0.2s;
    transition: 0.2s;
    background: #efefef;
    background: var(--background);
    border-radius: 3px;
}

@media (prefers-color-scheme: dark) {

    input[type='range']::-moz-range-track {
        background: #161f27;
        background: var(--background);
    }
}

input[type='range']::-moz-range-thumb {
    box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #dbdbdb;
    background: var(--border);
}

@media (prefers-color-scheme: dark) {

    input[type='range']::-moz-range-thumb {
        background: #526980;
        background: var(--border);
    }
}

input[type='range']::-ms-track {
    width: 100%;
    height: 9.5px;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
}

input[type='range']::-ms-fill-lower {
    background: #efefef;
    background: var(--background);
    border: 0.2px solid #010101;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

@media (prefers-color-scheme: dark) {

    input[type='range']::-ms-fill-lower {
        background: #161f27;
        background: var(--background);
    }
}

input[type='range']::-ms-fill-upper {
    background: #efefef;
    background: var(--background);
    border: 0.2px solid #010101;
    border-radius: 3px;
    box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
}

@media (prefers-color-scheme: dark) {

    input[type='range']::-ms-fill-upper {
        background: #161f27;
        background: var(--background);
    }
}

input[type='range']::-ms-thumb {
    box-shadow: 1px 1px 1px #000, 0 0 1px #0d0d0d;
    border: 1px solid #000;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: #dbdbdb;
    background: var(--border);
}

@media (prefers-color-scheme: dark) {

    input[type='range']::-ms-thumb {
        background: #526980;
        background: var(--border);
    }
}

input[type='range']:focus::-ms-fill-lower {
    background: #efefef;
    background: var(--background);
}

@media (prefers-color-scheme: dark) {

    input[type='range']:focus::-ms-fill-lower {
        background: #161f27;
        background: var(--background);
    }
}

input[type='range']:focus::-ms-fill-upper {
    background: #efefef;
    background: var(--background);
}

@media (prefers-color-scheme: dark) {

    input[type='range']:focus::-ms-fill-upper {
        background: #161f27;
        background: var(--background);
    }
}

a {
    text-decoration: none;
    color: #0076d1;
    color: var(--links);
}

@media (prefers-color-scheme: dark) {

    a {
        color: #41adff;
        color: var(--links);
    }
}

a:hover {
    text-decoration: underline;
}

code {
    background: #efefef;
    background: var(--background);
    color: #000;
    color: var(--code);
    padding: 2.5px 5px;
    border-radius: 6px;
    font-size: 1em;
}

@media (prefers-color-scheme: dark) {

    code {
        color: #ffbe85;
        color: var(--code);
    }
}

@media (prefers-color-scheme: dark) {

    code {
        background: #161f27;
        background: var(--background);
    }
}

samp {
    background: #efefef;
    background: var(--background);
    color: #000;
    color: var(--code);
    padding: 2.5px 5px;
    border-radius: 6px;
    font-size: 1em;
}

@media (prefers-color-scheme: dark) {

    samp {
        color: #ffbe85;
        color: var(--code);
    }
}

@media (prefers-color-scheme: dark) {

    samp {
        background: #161f27;
        background: var(--background);
    }
}

time {
    background: #efefef;
    background: var(--background);
    color: #000;
    color: var(--code);
    padding: 2.5px 5px;
    border-radius: 6px;
    font-size: 1em;
}

@media (prefers-color-scheme: dark) {

    time {
        color: #ffbe85;
        color: var(--code);
    }
}

@media (prefers-color-scheme: dark) {

    time {
        background: #161f27;
        background: var(--background);
    }
}

pre > code {
    padding: 10px;
    display: block;
    overflow-x: auto;
}

var {
    color: #39a33c;
    color: var(--variable);
    font-style: normal;
    font-family: monospace;
}

@media (prefers-color-scheme: dark) {

    var {
        color: #d941e2;
        color: var(--variable);
    }
}

kbd {
    background: #efefef;
    background: var(--background);
    border: 1px solid #dbdbdb;
    border: 1px solid var(--border);
    border-radius: 2px;
    color: #363636;
    color: var(--text-main);
    padding: 2px 4px 2px 4px;
}

@media (prefers-color-scheme: dark) {

    kbd {
        color: #dbdbdb;
        color: var(--text-main);
    }
}

@media (prefers-color-scheme: dark) {

    kbd {
        border: 1px solid #526980;
        border: 1px solid var(--border);
    }
}

@media (prefers-color-scheme: dark) {

    kbd {
        background: #161f27;
        background: var(--background);
    }
}

img,
video {
    max-width: 100%;
    height: auto;
}

hr {
    border: none;
    border-top: 1px solid #dbdbdb;
    border-top: 1px solid var(--border);
}

@media (prefers-color-scheme: dark) {

    hr {
        border-top: 1px solid #526980;
        border-top: 1px solid var(--border);
    }
}

table {
    border-collapse: collapse;
    margin-bottom: 10px;
    width: 100%;
    table-layout: fixed;
}

table caption {
    text-align: left;
}

td,
th {
    padding: 6px;
    text-align: left;
    vertical-align: top;
    word-wrap: break-word;
}

thead {
    border-bottom: 1px solid #dbdbdb;
    border-bottom: 1px solid var(--border);
}

@media (prefers-color-scheme: dark) {

    thead {
        border-bottom: 1px solid #526980;
        border-bottom: 1px solid var(--border);
    }
}

tfoot {
    border-top: 1px solid #dbdbdb;
    border-top: 1px solid var(--border);
}

@media (prefers-color-scheme: dark) {

    tfoot {
        border-top: 1px solid #526980;
        border-top: 1px solid var(--border);
    }
}

tbody tr:nth-child(even) {
    background-color: #efefef;
    background-color: var(--background);
}

@media (prefers-color-scheme: dark) {

    tbody tr:nth-child(even) {
        background-color: #161f27;
        background-color: var(--background);
    }
}


::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #efefef;
    background: var(--background);
    border-radius: 6px;
}

@media (prefers-color-scheme: dark) {

    ::-webkit-scrollbar-track {
        background: #161f27;
        background: var(--background);
    }
}

::-webkit-scrollbar-thumb {
    background: rgb(170, 170, 170);
    background: var(--scrollbar-thumb);
    border-radius: 6px;
}

@media (prefers-color-scheme: dark) {

    ::-webkit-scrollbar-thumb {
        background: #040a0f;
        background: var(--scrollbar-thumb);
    }
}

@media (prefers-color-scheme: dark) {

    ::-webkit-scrollbar-thumb {
        background: #040a0f;
        background: var(--scrollbar-thumb);
    }
}


@media (prefers-color-scheme: dark) {

    ::-webkit-scrollbar-thumb:hover {
        background: rgb(0, 0, 0);
        background: var(--scrollbar-thumb-hover);
    }
}

::-moz-selection {
    background-color: #9e9e9e;
    background-color: var(--selection);
    color: #000;
    color: var(--text-bright);
}

::selection {
    background-color: #9e9e9e;
    background-color: var(--selection);
    color: #000;
    color: var(--text-bright);
}

@media (prefers-color-scheme: dark) {

    ::-moz-selection {
        color: #fff;
        color: var(--text-bright);
    }

    ::selection {
        color: #fff;
        color: var(--text-bright);
    }
}

@media (prefers-color-scheme: dark) {

    ::-moz-selection {
        background-color: #1c76c5;
        background-color: var(--selection);
    }

    ::selection {
        background-color: #1c76c5;
        background-color: var(--selection);
    }
}

details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #f7f7f7;
    background-color: var(--background-alt);
    padding: 10px 10px 0;
    margin: 1em 0;
    border-radius: 6px;
    overflow: hidden;
}

@media (prefers-color-scheme: dark) {

    details {
        background-color: #1a242f;
        background-color: var(--background-alt);
    }
}

details[open] {
    padding: 10px;
}

details > :last-child {
    margin-bottom: 0;
}

details[open] summary {
    margin-bottom: 10px;
}

summary {
    display: list-item;
    background-color: #efefef;
    background-color: var(--background);
    padding: 10px;
    margin: -10px -10px 0;
    cursor: pointer;
    outline: none;
}

@media (prefers-color-scheme: dark) {

    summary {
        background-color: #161f27;
        background-color: var(--background);
    }
}

summary:hover,
summary:focus {
    text-decoration: underline;
}

details > :not(summary) {
    margin-top: 0;
}

summary::-webkit-details-marker {
    color: #363636;
    color: var(--text-main);
}

@media (prefers-color-scheme: dark) {

    summary::-webkit-details-marker {
        color: #dbdbdb;
        color: var(--text-main);
    }
}

dialog {
    background-color: #f7f7f7;
    background-color: var(--background-alt);
    color: #363636;
    color: var(--text-main);
    border: none;
    border-radius: 6px;
    border-color: #dbdbdb;
    border-color: var(--border);
    padding: 10px 30px;
}

@media (prefers-color-scheme: dark) {

    dialog {
        border-color: #526980;
        border-color: var(--border);
    }
}

@media (prefers-color-scheme: dark) {

    dialog {
        color: #dbdbdb;
        color: var(--text-main);
    }
}

@media (prefers-color-scheme: dark) {

    dialog {
        background-color: #1a242f;
        background-color: var(--background-alt);
    }
}

dialog > header:first-child {
    background-color: #efefef;
    background-color: var(--background);
    border-radius: 6px 6px 0 0;
    margin: -10px -30px 10px;
    padding: 10px;
    text-align: center;
}

@media (prefers-color-scheme: dark) {

    dialog > header:first-child {
        background-color: #161f27;
        background-color: var(--background);
    }
}


dialog::backdrop {
    background: #0000009c;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
}

footer {
    border-top: 1px solid #dbdbdb;
    border-top: 1px solid var(--border);
    padding-top: 10px;
    color: #70777f;
    color: var(--text-muted);
}

@media (prefers-color-scheme: dark) {

    footer {
        color: #a9b1ba;
        color: var(--text-muted);
    }
}

@media (prefers-color-scheme: dark) {

    footer {
        border-top: 1px solid #526980;
        border-top: 1px solid var(--border);
    }
}

body > footer {
    margin-top: 40px;
}

@media print {
    body,
    pre,
    code,
    summary,
    details,
    input,
    textarea {
        background-color: #fff;
    }

    input,
    textarea {
        border: 1px solid #000;
    }

    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    button,
    input,
    textarea,
    footer,
    summary,
    strong {
        color: #000;
    }

    summary::marker {
        color: #000;
    }

    summary::-webkit-details-marker {
        color: #000;
    }

    tbody tr:nth-child(even) {
        background-color: #f2f2f2;
    }

    a {
        color: #00f;
        text-decoration: underline;
    }
}

.parent {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
}


body {
    background-image: url("../image/bleue.png")
}

.div1 {

    width: 100%;
}

.div2 {

    width: 100%;
}

.div3 {

    width: 100%;
}

li {
    color: #a1998b;
}

body {
    max-width: 1900px;
}

.calendrier {
    width: 40em;
}

h1, h2 {
    color: black;
    font-size: 1.2em;
}

th {
    color: #7c6f60;
}

thead th {
    color: #b29345;
}


.menu {
    background-color: rgba(4, 250, 183, 0.76); /* Dégradé de vert (#00ff00) à bleu (#0000ff) */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 10px;
    width: 80%;
    padding: 10px;


}

.pricipale {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-content: center;
    flex-wrap: wrap;
}

button {
    text-align: center;
    font-size: 1.5em;
    color: black;
    border: 3px solid #cae0e0;
}

input {
    text-align: center;
}

button:hover {
    background-color: #86eae2;
}

@media only screen and (max-width: 1300px) {
    .menu {
        background-color: rgba(4, 250, 183, 0.2); /* Dégradé de vert (#00ff00) à bleu (#0000ff) */
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        padding: 5px;
        color: #41adff;

    }

    body {
        width: 100vw;
        height: 100vh;
    }

    button {
        width: 250px;
        height: 100px;
    }

    .pricipale {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
    }

    .menu ul {
        list-style: none;
        padding: 0;
    }

    .menu li {
        font-size: 1.5em; /* Taille de la police */
    }

    input {
        background-color: black
    }

    h1, h2, li, a, label, a {
        font-size: 1.9em;
        font-weight: bold;
    }


    .parent {
        display: flex;
        flex-direction: column;
    }

    .div1 {

        width: 100%;
        height: 35%;
    }

    .div2 {

        width: 100%;
        height: 35%;
    }

    .div3 {

        width: 100%;
        height: 30%;
    }

    li {
        color: #696359;
    }

    body {
        max-width: 1900px;
    }

    .calendrier {
        width: 20em;
    }

    th {
        font-size: 1em;

    }

    h1 {
        color: #a639d2;
    }

    h2 {
        color: #8538b9;
    }

    a {
        color: #50c7b6;
    }

    label {
        font-size: 0.8em;
    }

    .divCentrer {
        display: none;
    }


    input {
        width: 55%;
        border-radius: 10px;
    }

}

select, option {
    color: white;
}

a {
    font-size: 1em;
    color: #1c76c5;
}

li {
    color: #1c76c5;
}

button {
    width: 190px;
    height: 60px;
    padding: 1px;
    color: #fff;
    border-radius: 5px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgb(55, 27, 234),
    7px 7px 20px 0px rgba(0, 0, 0, .1),
    4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
    border: none;
    background-color: #150223;
}


li :hover {
    background-color: #5cb0b0;

}

@media only screen and (max-width: 600px) {
    h1 {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1em;
    }

    a {
        font-size: 1em;
        color: #1c76c5;
    }

    th {
        font-size: 0.6em;
        padding: 1px;
        margin: 5px;
    }

    label {
        font-size: 0.8em;
    }

    button {
        height: 60px;
        width: 250px;
        text-align: center;
        padding: 10px;
        font-size: 1em;

    }

    li {
        color: darkcyan;
    }

    .menu {
        font-size: 0.8em;
        color: #1c76c5;
    }

}
