
.panier {

    width: 10em;
    position: relative;
    right: 8em;
    font-size: 0.6em;
}
.fermer :hover{
    background-color: black;
}
@media only screen and (max-width: 600px) {
    .panier {

        width: 5em;
        position: relative;
        right: 2em;
        font-size: 0.6em;
        z-index: 3;
    }
}